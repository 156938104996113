<script setup lang="ts">
import type { InternetOffer, TvOffer } from "~/types/Offers";
import type { Nullish } from "~/types/Helpers";
const props = defineProps<{
  internetOffer?: Nullish<InternetOffer>;
  tvOffer?: Nullish<TvOffer>;
  hideBtn?: boolean;
  hideContract?: boolean;
  to?: any;
}>();

const options = computed(() => {
  if (props.internetOffer) {
    return mergeVariants<InternetOffer>(props.internetOffer);
  }
  if (props.tvOffer) {
    return mergeVariants<TvOffer>(props.tvOffer);
  }
});
const selected = defineModel({ default: 0 });
</script>

<template>
  <div v-if="options" class="flex flex-col gap-6 max-w-sm">
    <div
      class="flex w-full bg-gray-100 rounded-xl py-2.5 relative overflow-hidden"
    >
      <button
        class="w-8 grow font-semibold z-20"
        @click="selected = i"
        v-for="(option, i) in options"
      >
        {{ option.symbol }}
      </button>
      <div
        class="absolute h-full bg-gray-200 inset-0 rounded-xl transition-transform"
        :style="{
          width: 100 / options?.length + '%',
          transform: `translate(${selected * 100}%)`,
        }"
      ></div>
    </div>
    <div>
      <TOffer
        :hideBtn
        :to
        :hideContract
        v-if="internetOffer"
        :internet-offer="options[selected] as InternetOffer"
        :highlight="selected == options?.length - 1"
      ></TOffer>
      <TOffer
        :to
        :hideBtn
        :hideContract
        v-if="tvOffer"
        :tv-offer="options[selected] as TvOffer"
        :highlight="selected == options?.length - 1"
      ></TOffer>
    </div>
  </div>
</template>

<style scoped></style>
